// material
import { paramCase } from "change-case";
import { useTheme, styled } from "@mui/material/styles";
import {
    Box,
    TableRow,
    Skeleton,
    TableCell,
    Typography,
    Stack,
    IconButton,
    Avatar,
    Tooltip,
} from "@mui/material";

import SyncIcon from '@mui/icons-material/Sync';
import { useEffect } from "react";


// const ThumbImgStyle = styled("img")(({ theme }) => ({
//     width: 50,
//     height: 50,
//     minWidth: 50,
//     objectFit: "cover",
//     background: theme.palette.background.default,
//     marginRight: theme.spacing(2),
//     border: "1px solid " + theme.palette.divider,
//     borderRadius: theme.shape.borderRadiusSm,
// }));
const label = { inputProps: { "aria-label": "Switch demo" } };
export default function ProductVariantsRow({ isLoading, row, handleClickOpen, mutate }) {
    const theme = useTheme();
    // const navigate = useNavigate();
    // const { i18n } = useTranslation();
    // const { language } = i18n;
    //  console.log("my row: ", row)

    return (
       
        <TableRow hover key={Math.random()}>
            <TableCell
                component="th"
                scope="row"
                padding="none"
                sx={{ maxWidth: 300 }}
            >
                <Stack direction="row" alignItems="center" spacing={1.5} >

                    {isLoading ? (
                        <Skeleton
                            variant="rectangular"
                            width={50}
                            height={50}
                            sx={{ borderRadius: 1 }}
                        />
                    ) : (
                        <Avatar> {row.variantName.slice(0, 1)} </Avatar>
                    )}
                    <Typography variant="subtitle2" noWrap>

                        {isLoading ? (
                            <Skeleton variant="text" width={120} sx={{ ml: 1 }} />
                        ) : (
                            row?.variantName
                        )}
                    </Typography>
                </Stack>
            </TableCell>
            <TableCell>
                    {isLoading ? (
                            <Skeleton variant="text" width={80} sx={{ ml: 1 }} />
                        ) : (
                            row?.sku
                    )}
                
            </TableCell>
            <TableCell>
       
                {isLoading ? (
                            <Skeleton variant="text" width={50} sx={{ ml: 1 }} />
                        ) : (
                            row?.color
                    )}
            </TableCell>
            <TableCell>
            {isLoading ? (
                            <Skeleton variant="text" width={50} sx={{ ml: 1 }} />
                        ) : (
                            row?.jst_qty
                    )}
            
            </TableCell>
            <TableCell>
            {isLoading ? (
                            <Skeleton variant="text" width={50} sx={{ ml: 1 }} />
                        ) : (
                            row?.qty
                    )}
           
            </TableCell>
            
            {/* <TableCell>

                {isLoading ? (
                    <Skeleton variant="text" />
                ) : (
                    row?.inventoryType
                )}
            </TableCell> */}
            {/* <TableCell>

                {isLoading ? (
                    <Skeleton variant="text" />
                ) : (
                    <Switch
                        {...label}
                        defaultChecked={row.isFeatured}
                        onChange={() => {
                            mutate({
                                isFeatured: !row.isFeatured,
                                id: row._id,
                            });
                        }}
                    />
                )}
            </TableCell> */}
            <TableCell align="right">
                {isLoading ? (
                    <Stack direction="row" justifyContent="flex-end">
                        <Skeleton
                            variant="circular"
                            width={34}
                            height={34}
                            sx={{ mr: 1 }}
                        />
                    </Stack>
                ) : (
                    <Stack direction="row" justifyContent="flex-end">
                        <Tooltip title="Sync">
                            {row?.jst_qty == row?.qty ? 
                                <SyncIcon style={{ color: 'gray' }} />
                            :
                            <IconButton
                            onClick={handleClickOpen({sku: row?.sku, qty: row.jst_qty})} >
                                <SyncIcon style={{ color: 'green' }}/>
                            </IconButton>
                            }
                        </Tooltip>
                       
                    </Stack>
                )}
            </TableCell>
        </TableRow>
    );
}
