import React from "react";
import * as api from "src/services";
// material
import {
  Typography,
  Card,
  CardContent,
  Stack,
  Button,
  Grid,
  TextField,
  Skeleton,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import Person4RoundedIcon from "@mui/icons-material/Person4Rounded";
import LocalShippingRoundedIcon from "@mui/icons-material/LocalShippingRounded";
import CreditCardRoundedIcon from "@mui/icons-material/CreditCardRounded";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { useMutation } from 'react-query';
import addresslookup from "./addresslookup.json";

export default function Details({ data, isLoading }) {
  const { t } = useTranslation("order");

  // console.log("data",data);
  const [isUserEditing, setIsUserEditing] = React.useState(false);
  const [isShipEditing, setIsShipEditing] = React.useState(false);

  const toggleUserEdit = () => {
    setIsUserEditing(!isUserEditing);
  };

  const toggleShipEdit = () => {
    setIsShipEditing(!isShipEditing);
  };

  const [fullName, setFullName] = React.useState(data?.user?.fullName || '');
  const [phone, setPhone] = React.useState(data?.user?.phone || '');
  const [email, setEmail] = React.useState(data?.user?.email || '');

  const [address, setAddress] = React.useState(data?.user?.address || '');
  const [zip, setZip] = React.useState(data?.user?.zip || '');
  const [city, setCity] = React.useState(addresslookup[data?.user?.city] || data?.user?.city || '');
  const [state, setState] = React.useState(addresslookup[data?.user?.state] || data?.user?.state || '');
  const [country, setCountry] = React.useState(addresslookup[data?.user?.country] || data?.user?.country || '');

  function converttxtdata(data) {
    let result = ""
    if (data?.selectedFont == "font1") {
      result += "字体: Jackie , ";
    } else if (data?.selectedFont == "font2") {
      result += "字体: Lobster , ";
    } else if (data?.selectedFont == "font3") {
      result += "字体: Marck, ";
    }

    const colors = {
      'red': '紅色',
      'orange': '橙色',
      'yellow': '黃色',
      'green': '綠色',
      'blue': '藍色',
      'purple': '紫色',
      'gold': '金色',
      'pink': '粉紅色',
      'grey': '灰色'

    };

    if (colors[data?.selectedColor]) {
      result += "颜色: " + colors[data?.selectedColor] + ", ";
    } else {
      result += "颜色: undefined";
    }

    result += "文字: " + data?.inputedText;

    return result;

  }

  const { isLoading: loadingUpdate, mutate } = useMutation(
    "update",
    api.updateOrder,
    {
      onSuccess: (data) => {
        toast.success(t(`common:errors.${data.message}`));
      },
      onError: (err) => {
        toast.error(
          t(`common:errors.${err.response.data.message}`) ||
          "Something went wrong!"
        );
      },
    }
  );

  const handleSave = async () => {
    const id = data._id.toString();
    const payload = {
      'user': {
        'fullName': fullName,
        'phone': phone,
        'email': email
      }
    };

    // how to call mutate  and pass the id and payload into it ?
    mutate({ id, ...payload });

    setIsUserEditing(false);
  };

  const handleShipSave = async () => {
    const id = data._id.toString();
    const payload = {
      'user': {
        'address': address,
        'zip': zip,
        'city': city,
        'state': state,
        'country': country
      }
    };

    mutate({ id, ...payload });

    setIsShipEditing(false);
  };



  React.useEffect(() => {
    if (data && data.user) {
      console.log(data)
      setFullName(data.user.fullName);
      setPhone(data.user.phone);
      setEmail(data.user.email);
      setAddress(data.user.address);
      setZip(data.user.zip);
      setCity(addresslookup[data.user.city] || data.user.city);
      setState(addresslookup[data.user.state] || data.user.state);
      setCountry(addresslookup[data.user.country] || data.user.country);
    }
  }, [data])

  return (
    <Stack sx={{ mt: 4 }}>
      <Grid spacing={2} container>
        <Grid item xs={12} md={4}>
          <Card sx={{ minHeight: 226 }}>
            <CardContent>
              <Stack
                spacing={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                {isLoading ? (
                  <>
                    <Skeleton variant="rect" width={50} height={50} />
                    <Skeleton variant="text" width={150} />
                  </>
                ) : (
                  <>
                    <Button
                      sx={{
                        display: "block",
                        minWidth: 50,
                        lineHeight: 0,
                        minHeight: 50,
                        color: "text.primary",
                        background: (theme) =>
                          alpha(theme.palette.primary.main, 0.3),
                      }}
                      variant="contained"
                      color="primary"
                      onClick={toggleUserEdit}
                    >
                      <Person4RoundedIcon />
                    </Button>
                    <Typography variant="h6">{t("customor-details")}</Typography>
                  </>
                )}
              </Stack>
              <Stack spacing={isLoading ? 0 : 1} sx={{ mt: 3 }}>
                {isLoading ? (
                  <>
                    <Skeleton variant="text" width={200} />
                    <Skeleton variant="text" width={200} />
                    <Skeleton variant="text" width={200} />
                  </>
                ) : (
                  <>
                    {isUserEditing ? (
                      <>
                        <TextField
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                        <TextField
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                        <TextField
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <Button onClick={handleSave}>Save</Button>
                      </>
                    ) : (<>
                      <Typography variant="body2">
                        <strong>{t("name")}</strong>: {fullName}
                      </Typography>
                      <Typography variant="body2">
                        <strong>{t("phone")}</strong>: {phone}
                      </Typography>
                      <Typography
                        sx={{
                          wordWrap: "break-word",
                        }}
                        variant="body2"
                      >
                        <strong>{t("email")}</strong>: {email}
                      </Typography>
                    </>
                    )}


                  </>
                )}
              </Stack>

            </CardContent>

          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ minHeight: 226 }}>
            <CardContent>
              <Stack
                spacing={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                {isLoading ? (
                  <>
                    <Skeleton variant="rect" width={50} height={50} />
                    <Skeleton variant="text" width={150} />
                  </>
                ) : (
                  <>
                    <Button
                      onClick={toggleShipEdit}
                      sx={{
                        display: "block",
                        minWidth: 50,
                        lineHeight: 0,
                        minHeight: 50,
                        color: "text.primary",
                        background: (theme) =>
                          alpha(theme.palette.primary.main, 0.3),
                      }}
                      variant="contained"
                      color="primary"
                    >
                      <LocalShippingRoundedIcon />
                    </Button>
                    <Typography variant="h6">{t("shipping-address")}</Typography>
                  </>
                )}
              </Stack>
              <Stack spacing={isLoading ? 0 : 1} sx={{ mt: 3 }}>
                {isLoading ? (
                  <>
                    <Skeleton variant="text" width={200} />
                    <Skeleton variant="text" width={200} />
                    <Skeleton variant="text" width={200} />
                  </>
                ) : (
                  <>
                    {isShipEditing ? (
                      <>
                        <TextField
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                        <TextField
                          value={zip}
                          onChange={(e) => setZip(e.target.value)}
                        />
                        <TextField
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                        <TextField
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                        />
                        <TextField
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        />
                        <Button onClick={handleShipSave}>Save</Button>
                      </>
                    ) : (
                      <>
                        <Typography variant="body2">
                          <strong>{t("address")}</strong>: {address} {zip},{" "}
                          {city} {state}, {country}
                        </Typography>
                        <Typography variant="body2">
                          <strong>{t("order-date")}</strong>:{" "}
                          {new Date(data?.createdAt).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            minute: "numeric",
                            hour: "numeric",
                          })}
                        </Typography>
                      </>
                    )}
                  </>
                )}
              </Stack>
            </CardContent>

          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ minHeight: 226 }}>
            <CardContent>
              <Stack
                spacing={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                {isLoading ? (
                  <>
                    <Skeleton variant="rect" width={50} height={50} />
                    <Skeleton variant="text" width={150} />
                  </>
                ) : (
                  <>
                    <Button
                      sx={{
                        display: "block",
                        minWidth: 50,
                        lineHeight: 0,
                        minHeight: 50,
                        color: "text.primary",
                        background: (theme) =>
                          alpha(theme.palette.primary.main, 0.3),
                        path: {
                          stroke: (theme) => theme.palette.text.primary,
                        },
                      }}
                      variant="contained"
                      color="primary"
                    >
                      <CreditCardRoundedIcon />
                    </Button>
                    <Typography variant="h6">{t("payment-method")}</Typography>
                  </>
                )}
              </Stack>
              <Stack spacing={isLoading ? 0 : 1} sx={{ mt: 3 }}>
                {isLoading ? (
                  <>
                    <Skeleton variant="text" width={200} />
                    <Skeleton variant="text" width={200} />
                    <Skeleton variant="text" width={200} />
                  </>
                ) : (
                  <>
                    <Typography variant="body2">
                      <strong>{t("method")}</strong>: {data?.paymentMethod}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ textTransform: "capitalize" }}
                    >
                      <strong>{t("status")}</strong>: {data?.status}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ textTransform: "capitalize" }}
                    >
                      <strong>{t("shipping-fee")}</strong>: {data?.currency}{" "}
                      {data?.shipping}
                    </Typography>
                  </>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        {data?.extravalue && data.extravalue.trim() !== "" && (
          <Grid item xs={12}>
            <Typography variant="body1">{t("additional-information")}{" : "}{data.extravalue}</Typography>
          </Grid>
        )}
        {data?.items.filter(item => item.embadd).map((item, index) => (
          <Grid key={index} item xs={12}>
            <Typography variant="body1">
              {item.variantName}{" : "}{converttxtdata(item.embdata)}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}
