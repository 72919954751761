import { sum } from 'lodash';
import PropTypes from 'prop-types';
import { Page, View, Text, Font, Image, Document, StyleSheet } from '@react-pdf/renderer';
import addresslookup from "./addresslookup.json";
// utils
// import { fCurrency } from "src/utils/formatNumber";

// ----------------------------------------------------------------------

Font.register({
    family: 'NotoSansSC',
    fonts: [
      { src: '/fonts/NotoSansTC-Regular.ttf' },
      { src: '/fonts/NotoSansTC-Bold.ttf' }
    ]
});

const styles = StyleSheet.create({
    body1: {
        fontFamily: 'NotoSansSC',
      },
    col4: { width: '25%' },
    col8: { width: '75%' },
    col6: { width: '50%' },
    mb8: { marginBottom: 8 },
    mb40: { marginBottom: 40 },
    overline: {
        fontSize: 8,
        marginBottom: 8,
        fontWeight: 700,
        letterSpacing: 1.2,
        textTransform: 'uppercase'
    },
    h3: { fontSize: 16, fontWeight: 700 },
    h4: { fontSize: 13, fontWeight: 700 },
    body1: { fontSize: 10 },
    subtitle2: { fontSize: 9, fontWeight: 700 },
    alignRight: { textAlign: 'right' },
    page: {
        padding: '40px 24px 0 24px',
        fontSize: 9,
        lineHeight: 1.6,
        fontFamily: 'NotoSansSC',
        backgroundColor: '#fff',
        textTransform: 'capitalize'
    },
    footer: {
        left: 0,
        right: 0,
        bottom: 0,
        padding: 24,
        margin: 'auto',
        borderTopWidth: 1,
        borderStyle: 'solid',
        position: 'absolute',
        borderColor: '#DFE3E8'
    },
    gridContainer: { flexDirection: 'row', justifyContent: 'space-between' },
    table: { display: 'flex', width: 'auto' },
    tableHeader: {},
    tableBody: {},
    tableRow: {
        padding: '8px 0',
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderStyle: 'solid',
        borderColor: '#DFE3E8'
    },
    noBorder: { paddingTop: 8, paddingBottom: 0, borderBottomWidth: 0 },
    tableCell_1: { width: '5%' },
    tableCell_2: { width: '50%', paddingRight: 16 },
    tableCell_3: { width: '15%' }
});

// ----------------------------------------------------------------------

InvoicePDF.propTypes = {
    data: PropTypes.object.isRequired
};

export default function InvoicePDF({ data }) {
  

    function fCurrency(price) {
        if (price !== undefined && price !== null && !isNaN(price)) {
            const formattedPrice = Number(price).toFixed(2);
            return data.currency + formattedPrice;
        } else {
            return `${data.currency} + ${price}`;
        }
    }

    function converttxtdata(data) {
        let result = ""
        if (data.selectedFont == "font1"){
          result += "字體: Jackie, " ;
        } else if (data.selectedFont == "font2"){
          result += "字體: Lobster, ";
        } else if (data.selectedFont == "font3"){
          result += "字體: Marck, ";
        } 
    
        const colors = {
            'red': '紅色',
            'orange': '橙色',
            'yellow': '黃色',
            'green': '綠色',
            'blue': '藍色',
            'purple': '紫色',
            'gold': '金色',
            'pink': '粉紅色',
            'grey': '灰色'};
    
        if (colors[data?.selectedColor]) {
          result += "顏色: " + colors[data?.selectedColor] + ", ";
        } else {
          result += "顏色: undefined";
        }
    
        result += "文字: " + data?.inputedText;
    
        return result;
    
      }

    let totalqty = 0
    let totalamount  = 0
    let discountamount = 0
    let netamount = 0
    let emb = 0

    const city = addresslookup[data?.user?.city] || data?.user?.city;
    const state = addresslookup[data?.user?.state] || data?.user?.state;
    const country = addresslookup[data?.user?.country] || country?.user?.country;

    const statusMapping = {
        "pending": "Pending shipment",
    };
      
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={[styles.gridContainer, styles.mb40]}>
                    <Image source="/logoMain.png" style={{ height: 32, }} />
                    <View style={{ alignItems: 'right', flexDirection: 'column' }}>
                        <Text style={styles.h3}>{statusMapping[data?.status] || data?.status}</Text>
                        <Text>INV-{data?._id}</Text>
                        {data?.jstOid ? (
                            <Text>Jushuitan Ref. ID: {data?.jstOid}</Text>
                        ) : null}
                    </View>
                </View>

                <View style={[styles.gridContainer, styles.mb40]}>
                    <View style={styles.col6}>
                        <Text style={[styles.overline, styles.mb8]}>Invoice from</Text>
                        <Text style={styles.body1}>Flat 1213B, 12/F., Landmark North, 39 Lung Sum Avenue, </Text>
                        <Text style={styles.body1}>Sheung Shui, New Territories, Hong Kong</Text>
                        <Text style={styles.body1}>+852 3568-4351</Text>
                        <Text style={styles.body1}> info@moonrockbags.com</Text>
                    </View>
                    <View style={styles.col6}>
                        <Text style={[styles.overline, styles.mb8]}>Invoice to</Text>
                        <Text style={styles.body1}>{data?.user?.fullName}</Text>
                        <Text style={styles.body1}>{data?.user?.address} {data?.user?.zip}, {city} {state}, {country}</Text>
                        <Text style={styles.body1}>{data?.user?.phone}</Text>
                        <Text style={styles.body1}>{data?.user?.email}</Text>
                    </View>
                </View>

                <Text style={[styles.overline, styles.mb8]}>Invoice Details</Text>

                <View style={styles.table}>
                    <View style={styles.tableHeader}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCell_1}>
                                <Text style={styles.subtitle2}>#</Text>
                            </View>
                            <View style={styles.tableCell_2}>
                                <Text style={styles.subtitle2}>Product Name</Text>
                            </View>
                            <View style={styles.tableCell_3}>
                                <Text style={styles.subtitle2}>Color</Text>
                            </View>
                            <View style={styles.tableCell_3}>
                                <Text style={styles.subtitle2}>Size</Text>
                            </View>
                            <View style={styles.tableCell_3}>
                                <Text style={styles.subtitle2}>Qty</Text>
                            </View>
                            <View style={styles.tableCell_3}>
                                <Text style={styles.subtitle2}>Unit price</Text>
                            </View>
                            <View style={styles.tableCell_3}>
                                <Text style={styles.subtitle2}>Discount</Text>
                            </View>
                            <View style={styles.tableCell_3}>
                                <Text style={styles.subtitle2}>Emb</Text>
                            </View>
                            <View style={[styles.tableCell_3, styles.alignRight]}>
                                <Text style={styles.subtitle2}>Total</Text>
                            </View>
                        </View>
                    </View>

                    <View style={styles.tableBody}>
                        {data?.items.map((item, index) => {

                            let price = item.price
                            let sprice = item.priceSale
                            if (item?.embadd) {
                                emb = emb + 50
                                sprice = sprice - 50
                            }
                            let discount = item.price - sprice
                            let discount_persentage = ((discount / item.price) * 100).toFixed(1) + '%';
                            let total = item.priceSale * item.quantity

                            totalqty = totalqty + item.quantity
                            totalamount = totalamount + (price * item.quantity)
                            discountamount = discountamount + (discount * item.quantity)
                            netamount = netamount + total
                            return (
                            <View style={styles.tableRow} key={Math.random()}>
                                <View style={styles.tableCell_1}>
                                    <Text>{index + 1}</Text>
                                </View>
                                <View style={styles.tableCell_2}>
                                    <Text style={styles.subtitle2}>{item.variantName}</Text>
                                    {item?.embadd && <Text style={styles.subtitle2}>刺繡: {converttxtdata(item?.embdata)}</Text>}
                                </View>
                                <View style={styles.tableCell_3}>
                                    <Text>{item.color}</Text>
                                </View>
                                <View style={styles.tableCell_3}>
                                    <Text>{item.size}</Text>
                                </View>
                                <View style={styles.tableCell_3}>
                                    <Text>{item.quantity}</Text>
                                </View>
                                <View style={styles.tableCell_3}>
                                    <Text>{fCurrency(price)}</Text>
                                </View>
                                <View style={styles.tableCell_3}>
                                    <Text>{`${fCurrency(discount)}  ${discount_persentage}`}</Text>
                                </View>
                                <View style={styles.tableCell_3}>
                                    <Text>{fCurrency(emb)}</Text>
                                </View>
                                <View style={[styles.tableCell_3, styles.alignRight]}>
                                    <Text>{fCurrency(total)}</Text>
                                </View>
                            </View>
                        )})}

                        <View style={[styles.tableRow, styles.noBorder]}>
                            {/* <View style={styles.tableCell_1} /> */}
                            <View style={styles.tableCell_2} />
                            <View style={styles.tableCell_3} />
                            <View style={styles.tableCell_2}>
                                <Text>Total Quantity</Text>
                            </View>
                            <View style={[styles.tableCell_3, styles.alignRight]}>
                                <Text>
                                    {totalqty}
                                </Text>
                            </View>
                        </View>

                        <View style={[styles.tableRow, styles.noBorder]}>
                            {/* <View style={styles.tableCell_1} />*/}
                            <View style={styles.tableCell_2} /> 
                            <View style={styles.tableCell_3} />
                            <View style={styles.tableCell_2}>
                                <Text>Grand Total Amount</Text>
                            </View>
                            <View style={[styles.tableCell_3, styles.alignRight]}>
                                <Text>
                                    {fCurrency(totalamount) }
                                </Text>
                            </View>
                        </View>

                        <View style={[styles.tableRow, styles.noBorder]}>
                            {/* <View style={styles.tableCell_1} />*/}
                            <View style={styles.tableCell_2} />
                            <View style={styles.tableCell_3} /> 
                            <View style={styles.tableCell_2}>
                                <Text>Discount Amount</Text>
                            </View>
                            <View style={[styles.tableCell_3, styles.alignRight]}>
                                <Text>
                                    {fCurrency(discountamount)}
                                </Text>
                            </View>
                        </View>


                        <View style={[styles.tableRow, styles.noBorder]}>
                            {/* <View style={styles.tableCell_1} /> */}
                            <View style={styles.tableCell_2} />
                            <View style={styles.tableCell_3} />
                            <View style={styles.tableCell_2}>
                                <Text>Shipping</Text>
                            </View>
                            <View style={[styles.tableCell_3, styles.alignRight]}>
                                <Text>
                                    {fCurrency(data?.shipping)}
                                </Text>
                            </View>
                        </View>

                        {emb > 0 && <View style={[styles.tableRow, styles.noBorder]}>
                            {/* <View style={styles.tableCell_1} /> */}
                            <View style={styles.tableCell_2} />
                            <View style={styles.tableCell_3} />
                            <View style={styles.tableCell_2}>
                                <Text>Embroidery Service</Text>
                            </View>
                            <View style={[styles.tableCell_3, styles.alignRight]}>
                                <Text>
                                    {fCurrency(emb)}
                                </Text>
                            </View>
                        </View>}

                        {data.discount > 0 && <View style={[styles.tableRow, styles.noBorder]}>
                            {/* <View style={styles.tableCell_1} /> */}
                            <View style={styles.tableCell_2} />
                            <View style={styles.tableCell_3} />
                            <View style={styles.tableCell_2}>
                                <Text>Discount (Promotion)</Text>
                            </View>
                            <View style={[styles.tableCell_3, styles.alignRight]}>
                                <Text>
                                    {fCurrency(data.discount)}
                                </Text>
                            </View>
                        </View>}

                        <View style={[styles.tableRow, styles.noBorder]}>
                            {/* <View style={styles.tableCell_1} />*/}
                            <View style={styles.tableCell_2} /> 
                            <View style={styles.tableCell_3} />
                            <View style={styles.tableCell_2}>
                                <Text style={styles.h4}>Total Net Amount</Text>
                            </View>
                            <View style={[styles.tableCell_3, styles.alignRight]}>
                                <Text style={styles.h4}>
                                    {fCurrency(data?.total)}
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={[styles.gridContainer, styles.footer]}>
                    <View style={styles.col8}>
                        <Text style={styles.subtitle2}>NOTES</Text>
                        <Text>We appreciate your business. Should you need us to add VAT or extra notes let us know!</Text>
                    </View>
                    <View style={[styles.col4, styles.alignRight]}>
                        <Text style={styles.subtitle2}>Have a Question?</Text>
                        <Text> info@moonrockbags.com</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
}
