import React from "react";
// material
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import * as api from "src/services";
import { useTranslation } from "react-i18next";

export default function SyncDialog({
  onClose,
  data,
  successReturncall,
  endPoint,
  type,
  syncMessage,
}) {
  const { t } = useTranslation("common");

  const { isLoading, mutate } = useMutation(api[endPoint], {
    onSuccess: () => {
      toast.success(type);
      successReturncall(data);
      onClose();
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const handleSync = () => {
    console.log("sync.. ", data)
    mutate(data);
  };


  return (
    <>
      <DialogTitle sx={{ display: "flex", alignItems: "flex-start", mb: 1 }}>
        <WarningRoundedIcon sx={{ mr: 1 }} /> {t("warning")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {syncMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}> {t("cancel")} </Button>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={handleSync}>
          {t("sync")}
        </LoadingButton>
      </DialogActions>
    </>
  );
}
