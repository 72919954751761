// material
import { paramCase } from "change-case";
import { useTheme, styled } from "@mui/material/styles";
import {
  Box,
  TableRow,
  Skeleton,
  TableCell,
  Typography,
  Stack,
  IconButton,
  Avatar,
//  Rating,
  Switch,
  Tooltip,
  Link,
} from "@mui/material";
// redux
import { fCurrency } from "src/utils/formatNumber";
import { fDateShort } from "src/utils/formatTime";
// components
import { Label } from "src/components";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import SyncIcon from '@mui/icons-material/Sync';
import { useNavigate } from "react-router-dom";
import {  enUS } from "date-fns/locale";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { LanguageOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";

const ThumbImgStyle = styled("img")(({ theme }) => ({
  width: 50,
  height: 50,
  minWidth: 50,
  objectFit: "cover",
  background: theme.palette.background.default,
  marginRight: theme.spacing(2),
  border: "1px solid " + theme.palette.divider,
  borderRadius: theme.shape.borderRadiusSm,
}));
const label = { inputProps: { "aria-label": "Switch demo" } };
export default function ProductRow({ deflang, isLoading, row, handleClickOpen, mutate }) {
  const theme = useTheme();
  const navigate = useNavigate();
  // const [isdone, setDone] = useState(false);

  // useEffect(() => {
  //   try {
  //     const langdata = JSON.parse(row?.lang || "{}");
  //     if (langdata?.name_en && langdata?.name_zh) {
  //       setDone(true);
  //     } else {
  //       setDone(false);
  //     }
  //   } catch (error) {
  //     // Handle invalid JSON here
  //     setDone(false);
  //   }
  // }, []);

  let name = "";
  let isdone = false;
  if (row?.lang && row.lang.length > 0) {
    const langdata = JSON.parse(row?.lang);
    name = langdata[`name_${deflang}`];
    isdone = true;
  } else {
    name = row?.name;
  }

  return (
    <TableRow hover key={Math.random()}>
      <TableCell
        component="th"
        scope="row"
        padding="none"
        sx={{ maxWidth: 300 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}>
          {isLoading ? (
            <Skeleton
              variant="rectangular"
              width={50}
              height={50}
              sx={{ borderRadius: 1 }}
            />
          ) : Boolean(row.cover) > 0 ? (
            <ThumbImgStyle alt={row?.name} src={row?.cover} />
          ) : (
            <Avatar> {row.name.slice(0, 1)} </Avatar>
          )}
          <Typography variant="subtitle2" noWrap>
            {isLoading ? (
              <Skeleton variant="text" width={120} sx={{ ml: 1 }} />
            ) : (
              name 
            )}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        {isLoading ? (
          <Skeleton variant="text" />
        ) : (
          <>
            {fDateShort( row?.createdAt, enUS  )}
          </>
        )}
      </TableCell>
      <TableCell>
        {isLoading ? (
          <Skeleton variant="text" />
        ) : (
          <Label
            variant={theme.palette.mode === "light" ? "ghost" : "filled"}
            color={
              (row?.variants[0].available < 1 && "error") ||
              (row?.variants[0].available < 20 && "warning") ||
              (row?.variants[0].available >= 20 && "success") ||
              "primary"
            }>
            {/* {row?.status} */}
            {(row?.variants[0].available < 1 && "Out of stock") ||
              (row?.variants[0].available < 20 && "Low stock") ||
              (row?.variants[0].available >= 20 && "In stock")}
          </Label>
        )}
      </TableCell>
      <TableCell align="left">
        {isLoading ? (
          <Skeleton variant="text" />
        ) : (
          <>{row?.variants[0].sku}</>
        )}
      </TableCell>
      <TableCell>
        {isLoading ? (
          <Skeleton variant="text" />
        ) : (
          fCurrency(row?.variants[0].salePrice || row?.variants[0].price)
        )}
      </TableCell>
      <TableCell>
        {isLoading ? (
          <Skeleton variant="text" />
        ) : (
          // <Switch
          //   {...label}
          //   defaultChecked={row.isFeatured}
          //   onChange={() => {
          //     mutate({
          //       isFeatured: !row.isFeatured,
          //       id: row._id,
          //     });
          //   }}
          // />
          row.isFeatured ? 
          <CheckCircleIcon sx={{ color: 'green' }} /> 
        : 
          <HighlightOffIcon sx={{ color: 'gray' }} />
        )}
      </TableCell>
      <TableCell align="right">
        {isLoading ? (
          <Stack direction="row" justifyContent="flex-end">
            <Skeleton
              variant="circular"
              width={34}
              height={34}
              sx={{ mr: 1 }}
            />
            <Skeleton
              variant="circular"
              width={34}
              height={34}
              sx={{ mr: 1 }}
            />
            <Skeleton variant="circular" width={34} height={34} />
          </Stack>
        ) : (
          <Stack direction="row" justifyContent="flex-end">

            <Tooltip title="Lang">
              <IconButton
                onClick={() => navigate(`/products/lang/${row?.slug}`)}>
                  {isdone ? <LanguageOutlined sx={{ color: 'green' }} /> :  <LanguageOutlined sx={{ color: 'red' }} />}
              </IconButton>
            </Tooltip>

            <Tooltip title="Sync">
              <IconButton
                onClick={() => navigate(`/products/sync/${row?.slug}`)}>
                <SyncIcon />
              </IconButton>
            </Tooltip>

            <Link
              target="_blank"
              href={`https://moonrockbags.com/product/${
                row?.slug
              }`}>
              <IconButton>
                <RemoveRedEyeIcon />
              </IconButton>
            </Link>


            <Tooltip title="Edit">
              <IconButton
                onClick={() => navigate(`/products/${row?.slug}`)}>
                <EditRoundedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton onClick={handleClickOpen(row.slug)}>
                <DeleteRoundedIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
      </TableCell>
    </TableRow>
  );
}
