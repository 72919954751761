import axios from 'axios';

async function urlExists(url) {
  try {
    await axios.get(url, { validateStatus: (status) => status === 200 });
    return true;
  } catch (error) {
    return false;
  }
}

function processCategories(categories){
  return categories.map(async (category) => {
    // Process the current category
    const processedCategory = processCategory(category);
    // Recursively process subCategories if present and populated
    if (processedCategory.subCategories && Array.isArray(processedCategory.subCategories)) {
      processedCategory.subCategories = await processSubCategories(processedCategory.subCategories);
    }
    return processedCategory;
  });
}

async function processCategory(category){
  
  if (category.cover && category.cover.url) {
    const match = category.cover.url.match(/\/([^/]+)\.[^/]+$/);
    if (match && match.length > 1) {
      const identifier = match[1];
      const webpFileName = `${identifier}.webp`;
      const imgPath = `https://www.moonrockbags.com/images/products/${webpFileName}`;
      if (await urlExists(imgPath)) {
        // Replace the URL with the local .webp version
        category.cover.url = imgPath;
      }
    }
  }

  return category;
}

async function processSubCategories(subCategories){
  return Promise.all(subCategories.map(async (subcategory) => {
    if (subcategory.cover && subcategory.cover.url) {
      const match = subcategory.cover.url.match(/\/([^/]+)\.[^/]+$/);
      if (match && match.length > 1) {
        const identifier = match[1];
        const webpFileName = `${identifier}.webp`;
        const imgPath = `https://www.moonrockbags.com/images/products/${webpFileName}`;
        if (await urlExists(imgPath)) {
          // Replace the URL with the local .webp version
          subcategory.cover.url = imgPath;
        }
      }
    }
    return subcategory;
  }));
}

async function processProduct(product){
  if (product.cover) {
    product.cover = await processUrl(product.cover);
  }

  if (product.variants && Array.isArray(product.variants)) {
    product.variants = await Promise.all(product.variants.map(async (variant) => {
      if (variant.images && Array.isArray(variant.images)) {
        variant.images = await Promise.all(variant.images.map(async (image) => {
          if (image.url) {
            image.url = await processUrl(image.url);
          }
          return image;
        }));
      }
      return variant;
    }));
  }

  return product;
}

async function processProducts(products) {
  return Promise.all(products.map(async (product) => {
    // Process the cover URL
    if (product.cover) {
      product.cover = await processUrl(product.cover);
    }
    // Process the images in variants
    if (product.variants && Array.isArray(product.variants)) {
      product.variants = await Promise.all(product.variants.map(async (variant) => {
        if (variant.images && Array.isArray(variant.images)) {
          variant.images = await Promise.all(variant.images.map(async (image) => {
            if (image.url) {
              image.url = await processUrl(image.url);
            }
            return image;
          }));
        }
        return variant;
      }));
    }

    return product;
  }));
}


async function processProductsfirstlayer(products) {
  return Promise.all(products.map(async (product) => {
    // Process the cover URL
    if (product.cover) {
      product.cover = await processUrl(product.cover);
    }
    return product;
  }));
}

async function processUrl(url) {
  const match = url.match(/\/([^/]+)\.[^/]+$/);
  if (match && match.length > 1) {
    const identifier = match[1];
    const webpFileName = `${identifier}.webp`;
    const imgPath = `https://www.moonrockbags.com/images/products/${webpFileName}`;
    const aikhPath = `https://www.aikh.co/images/products/${webpFileName}`;
    if (await urlExists(imgPath)) {
      return imgPath;
    } else if (await urlExists(aikhPath)) {
      return aikhPath;
    }
  }
  console.log(`Returning original URL: ${url}`);
  return url;
}

export {
  processCategories,
  processCategory,
  processSubCategories,
  processProducts,
  processProduct,
  processUrl,
  processProductsfirstlayer,
};